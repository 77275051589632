import React from "react";
import styled from 'styled-components';
import { theme } from '../../../utils/theme';
import { Link } from "gatsby";
import Bounce from 'react-reveal/Bounce';


// ---- Images ----

import lamp from '../../../images/home/home_main.webp';
import lampOn from '../../../images/home/home_main_on.webp';
import lampHead from '../../../images/home/home_head_gradient.webp';
import lampHeadLightON from '../../../images/home/home_head_gradient_light_on.webp';
import lampTopFrame from '../../../images/home/home_top_frame_gradient.webp';
import lampTopFrameLightON from '../../../images/home/home_top_frame_gradient_light_on.webp';
import lampControllers from '../../../images/home/home_controllers_gradient.webp';
import lampBase from '../../../images/home/home_base_gradient.webp';
import Layout from "../../Layout/Layout";


// ---- Styles ----

const StyledLampWrapper = styled.div`
height: 99vh;
min-width: 20vw;

${theme.media.desktop} {
${theme.flex.centeredColumn};
height: 100%;
}
${theme.media.mobile} {
  img {
    max-height: 90vh;
  }
  }
`;

const StyledLamp = styled.div`
position: relative;
display: ${props => props.off ? 'none' : 'block'};

img {
height: 99vh;
position: relative;
filter: grayscale(100%);
opacity: ${(props) => props.children[1].props.active === 'true' ? 0 : 1};

${theme.media.desktop} {
filter: grayscale(0);
}
}

img:nth-child(2) {
  opacity: 0;
  position: absolute;
  left: 0;
  filter: grayscale(0);
  opacity: ${(props) => props.children[1].props.active === 'true' ? 1 : 0};
  z-index: ${(props) => props.children[1].props.active === 'true' ? 18 : 0};
}

${theme.media.desktop} {
display: ${props => props.left ? 'none' : 'block'};
display: ${props => props.off ? 'block' : 'none'};
}
`;
const StyledLampHead = styled.div`
position: absolute;
z-index: 14;

${theme.media.desktop} {
top: 0;
left: -50%;
position: relative;
}

img {
position: absolute;
height: 99vh;
visibility: hidden;
}

div {
z-index: 17;
height: 218px;
width: 150px;
border: 1px solid yellow;
position: absolute;
top: 70px;
left: 215px;
border-radius: 50px;
opacity: 0;

${theme.media.desktop} {
height: 20vh;
width: 20vw;
top: 6vh;
left: 25vw;
}

${theme.media.mobile} {
height: 20vh;
width: 25vw;
top: 5vh;
left: 35vw;
}

 @media(hover: hover) and (pointer: fine) {
        &:hover + img {
          visibility: visible;
        }
    }

  &:active + img {
    visibility: visible;
  }   
}

 @media(hover: hover) and (pointer: fine) {
        div:hover {
          z-index: 2;
        }
    }
`;
const StyledLampTopFrame = styled.div`
position: absolute;

img {
height: 99vh;
visibility: hidden;
}

div {
z-index: 3;
height: 34vh;
width: 70%;
border: 1px solid violet;
position: absolute;
top: 3vh;
left: 13%;
background: yellow;
clip-path: polygon(33% 22%, 71% 22%, 98% 0, 100% 47%, 68% 100%, 36% 100%, 3% 52%, 2% 6%);
opacity: 0;

 @media not all and (hover: none) {
        &:hover + img{
           visibility: visible;
        }
    }

  &:active + img {
    visibility: visible;
  } 
}

 @media not all and (hover: none) {
        &:hover {
           z-index: 2;
        }
    }

&:active {
  z-index: 2;
}
`;
const StyledLampControllers = styled.div`
position: absolute;

img {
height: 99vh;
visibility: hidden;
}

div {
z-index: 5;
height: 12vh;
width: 10vh;
border: 1px solid blue;
position: absolute;
top: 39vh;
left: 41%;
opacity: 0;

 @media not all and (hover: none) {
        &:hover + img{
           visibility: visible;
        }
    }

  &:active + img {
    visibility: visible;
  } 
}

 @media not all and (hover: none) {
        &:hover {
           z-index: 2;
        }
    }

&:active {
  z-index: 2;
}
`;
const StyledLampBase = styled.div`
position: absolute;

img {
height: 99vh;
visibility: hidden;
}

div {
z-index: 4;
height: 35vh;
width: 70%;
border: 1px solid violet;
position: absolute;
top: 59vh;
left: 16%;
background: yellow;
clip-path: polygon(36% 0, 61% 0, 69% 43%, 83% 79%, 74% 100%, 24% 100%, 14% 77%, 29% 42%);
opacity: 0;

 @media not all and (hover: none) {
        &:hover + img{
           visibility: visible;
        }
    }

  &:active + img {
    visibility: visible;
  } 
}

 @media not all and (hover: none) {
        &:hover {
           z-index: 2;
        }
    }

&:active {
  z-index: 2;
}
`;
const StyledInfo = styled.div`
  display: ${props => props.off ? 'block' : 'none'};

h4 {
  text-align: center;
  font-size: 22px;
  text-transform: uppercase;
  margin: 100px auto;
  opacity: .6;
}

${theme.media.minDesktop} {
display: ${props => props.off ? 'none' : 'block'};
}

`;


const LampComponent = props => {

  return (

    <Layout>
      <StyledLampWrapper>

        <StyledLampHead>
          <Link to="/head" aria-label="head">
            <div></div>
            {props.active === 'true' ? <img src={lampHeadLightON} alt="lampHeadLightON" /> : <img id="head" src={lampHead} alt="lampHeadLightOFF" />}
          </Link>
        </StyledLampHead>

        <StyledLampTopFrame>
          <Link to="/frame" aria-label="frame">
            <div></div>
            {props.active === 'true' ? <img src={lampTopFrameLightON} alt="lampTopFrameLightON" /> : <img src={lampTopFrame} alt="lampTopFrameLightOFF" />}
          </Link>
        </StyledLampTopFrame>

        <StyledLampControllers>
          <Link to="/controllers" aria-label="controllers">
            <div></div>
            <img src={lampControllers} alt="lampControllers" />
          </Link>
        </StyledLampControllers>

        <StyledLampBase>
          <Link to="/base" aria-label="base">
            <div></div>
            <img src={lampBase} alt="lampBase" />
          </Link>
        </StyledLampBase>

        <Bounce left>
          <StyledLamp>
            <img src={lamp} alt="lamp" />
            <img src={lampOn} alt="lampOn" active={props.active} />
          </StyledLamp>
        </Bounce>

        <Bounce >
          <StyledLamp off>
            <img src={lamp} alt="lamp" />
            <img src={lampOn} alt="lampOn" active={props.active} />
          </StyledLamp>
        </Bounce>

      </StyledLampWrapper>

      <StyledInfo off>
        <h4>click parts of the lamp</h4>
      </StyledInfo>

    </Layout>
  )
}

export default LampComponent;