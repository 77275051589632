import React from "react";
import styled from 'styled-components';
import { theme } from '../../../utils/theme';
import Layout from "../../Layout/Layout";


export const StyledFooterCentered = styled.div`
${theme.flex.centeredColumn};
position: absolute;
width: 100%;
text-align: center;
bottom: 1%;

p {
    ${theme.font.antic};
    color: #c8c7e2;
    font-size: .6rem;
    opacity: .3;
}

${theme.media.cloud} {
position: static;
width: 100%;
margin-top: 100px;
}
`;

const FooterComponent = () => (
    <Layout>
        <StyledFooterCentered>
            <p>materiei © {new Date().getFullYear()}</p>
        </StyledFooterCentered>
    </Layout>
)

export default FooterComponent;